<template>
<div>
  <v-app-bar
      color="deep-purple accent-4"
      :fixed="true"
      dense
      dark>
    <v-toolbar-title>Setting</v-toolbar-title>
  </v-app-bar>

  <div
    style="height: calc(100% - 50px - 56px); overflow-y: auto; margin-top: 48px; margin-bottom: 56px; width: 100%;">
    <div style="margin-top: 20px; text-align: center;">
      <v-btn small color="primary" @click="logout">Logout</v-btn>
    </div>

  </div>

</div>
</template>

<script>
import {EH_MOBILE_GLOBAL} from '@/helper.js'

export default {
  data () {
    return {

    }
  },
  methods: {
    logout () {
      window.location.href = EH_MOBILE_GLOBAL.logout_url
    }
  },
  created () {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
